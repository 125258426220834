import React from "react";
import FirstPage from "./FirstPage"
import AboutMe from "./AboutMe"
import Projects from "./Projects"
import Contact from "./Contact"


function App() {
    return (
        <div>
            <FirstPage/>
            <AboutMe/>
            <Projects/>
            <Contact/>
        </div>
    )
}

export default App