import React from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {FaGithub, FaLinkedin, FaInstagram} from "react-icons/fa";


function Contact() {
    return (
        <div>
            <div className="contacts" id="contact">
                <h1 style={{fontSize: "50px"}}>Let's work toghether!</h1>
                <div className="contacts-list">
                    <a href="https://github.com/RezaR-exe"><FaGithub size={100}/></a>
                    <a href="https://www.linkedin.com/in/andrei-daniel-panait-225678226/"><FaLinkedin size={100}/></a>
                    <a href="https://www.instagram.com/andrei_rz_94/"><FaInstagram size={100}/></a>
                </div>
            </div>
        </div>
    )
}

export default Contact;