import logo from "./static/outline.png";
import {useState} from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {FaGithub, FaLinkedin, FaInstagram} from "react-icons/fa";

function FirstPage() {
    const [buttonHover, setButtonHover] = useState(false);
    const [navClick, setClick] = useState(false);
    const defaultBurgerLine = "h-1 w-6 my-1 rounded-full bg-black transition ease transform duration-300";

    function handleClick() {
        navClick ? setClick(false) : setClick(true)
    }

    const customStyle = {
        boxShadow: "0 0 20px 10px #ff8906"
    }

    function handleHover() {
        buttonHover
            ? setButtonHover(false)
            : setButtonHover(true)
    }

    const customFont = {
        fontFamily: '"Angkor", serif',
        fontWeight: "400",
        fontStyle: "normal"
    }

    const customStyleForMobile = {
        display: "flex",
        flexDirection: "column",
        visibility: "visible"
    }

    const customStyleForMobileLinks = {
        height: "10rem",
        marginTop: "50px"
    }

    return (
        <div>
            <div id="front">
                <header>
                    <div className="header-content">
                        <a className="logo" href="#front"><img src={logo}/></a>
                        <div className="navbar-links">
                            <a href="#aboutme">About</a>
                            <a href="#projects">Projects</a>
                            <a href="#contact">Contact</a>
                            <button onClick={handleClick} className="hamburger flex flex-col h-12 w-12 border-2 border-black rounded justify-center items-center group">
                                <div className={`${defaultBurgerLine} ${navClick ? "rotate-45 translate-y-3 opacity-50 group-hover:opacity-100" : "opacity-50 group-hover:opacity-100"}`}></div>
                                <div className={`${defaultBurgerLine} ${navClick ? "opacity-0" : "opacity-50 group-hover:opacity-100"}`}></div>
                                <div className={`${defaultBurgerLine} ${navClick ? "-rotate-45 -translate-y-3 opacity-50 group-hover:opacity-100" : "opacity-50 group-hover:opacity-100"}`}></div>
                            </button>
                        </div>
                    </div>
                    <div className="slider">
                        <ul>
                            <li>
                                <a href="https://github.com/RezaR-exe"><FaGithub size={50}/></a>
                            </li>
                            <li>
                                <a href="https://www.linkedin.com/in/andrei-daniel-panait-225678226/"><FaLinkedin size={50}/></a>
                            </li>
                            <li>
                                <a href="https://www.instagram.com/andrei_rz_94/"><FaInstagram size={50}/></a>
                            </li>
                        </ul>
                    </div>
                    <div style={navClick ? customStyleForMobile : null} className="mobile-view">
                        <a onClick={handleClick} style={navClick ? customStyleForMobileLinks : null} href="#aboutme">About</a>
                        <a onClick={handleClick} style={navClick ? customStyleForMobileLinks : null} href="#projects">Projects</a>
                        <a onClick={handleClick} style={navClick ? customStyleForMobileLinks : null} href="#contact">Contact</a>
                    </div>
                </header>
                <div className="main">
                    <h1 className="title" style={customFont}>Panait Andrei Daniel</h1>
                    <h2 style={{
                        fontSize: "21px"
                    }}>Passionate
                        and creative web developer dedicated to crafting exceptional digital
                        experiences. With a blend of technical expertise and a keen eye for design, I
                        specialize in building modern, responsive websites and web applications that
                        leave a lasting impression.</h2>
                    <a href="#contact" id="aboutexample">
                        <button
                            onMouseLeave={handleHover}
                            onMouseOver={handleHover}
                            style={buttonHover
                            ? customStyle
                            : null}
                            className="contact-btn">Contact</button>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default FirstPage;
