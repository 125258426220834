import React from "react";
import styles from "./index.css"

function AboutMe() {
    return (
        <div className="edit">
            <div className="about-me" id="aboutme">
                <h1>ABOUT ME</h1>
                <p>Here's some information about me such as my current skills, my current
                    projects and what I currently am working on.</p>
                <div className="introduction-skills-parent">
                    <div className="introduction">
                        <h1>My introduction</h1>
                        <p>I am a Full Stack Web Developer with a passion for crafting immersive digital
                            experiences and a dedication to mastering the intricacies of both front-end and
                            back-end technologies, I've embarked on a mission to create elegant, efficient,
                            and user-centric web applications.</p>
                        <p>I like to contribute and help develop open source projects along with other
                            developers.</p>
                        <p>I'm open for job opportunities where I can develop my skills even further and
                            learn new things as well, do not hesitate to contact me if you have an
                            opportunity that matches my skills.</p>
                    </div>
                        <div
                            className="skillcontainer -mt-5 md:mt-1 flex flex-wrap md:flex-row md:flex-wrap justify-center md:justify-start">
                            <div
                                className="w-32  bg-red-900/50 shadow-xl hover:shadow-red-900 hover:bg-red-900/75 backdrop-blur hover:duration-300 hover:ease-in-out cursor-default md:w-auto py-1 px-3 md:py-2 md:px-6 md:m-5 my-2 mx-2 mx-0 text-center shadow-lg rounded-sm md:rounded-xl text-lg">React.js</div>
                            <div
                                className="w-32  bg-red-900/50 shadow-xl hover:shadow-red-900 hover:bg-red-900/75 backdrop-blur hover:duration-300 hover:ease-in-out cursor-default md:w-auto py-1 px-3 md:py-2 md:px-6 md:m-5 my-2 mx-2 mx-0 text-center shadow-lg rounded-sm md:rounded-xl text-lg">JavaScript</div>
                            <div
                                className="w-32  bg-red-900/50 shadow-xl hover:shadow-red-900 hover:bg-red-900/75 backdrop-blur hover:duration-300 hover:ease-in-out cursor-default md:w-auto py-1 px-3 md:py-2 md:px-6 md:m-5 my-2 mx-2 mx-0 text-center shadow-lg rounded-sm md:rounded-xl text-lg">Node.js</div>
                            <div
                                className="w-32  bg-red-900/50 shadow-xl hover:shadow-red-900 hover:bg-red-900/75 backdrop-blur hover:duration-300 hover:ease-in-out cursor-default md:w-auto py-1 px-3 md:py-2 md:px-6 md:m-5 my-2 mx-2 mx-0 text-center shadow-lg rounded-sm md:rounded-xl text-lg">EJS</div>
                            <div
                                className="w-32  bg-red-900/50 shadow-xl hover:shadow-red-900 hover:bg-red-900/75 backdrop-blur hover:duration-300 hover:ease-in-out cursor-default md:w-auto py-1 px-3 md:py-2 md:px-6 md:m-5 my-2 mx-2 mx-0 text-center shadow-lg rounded-sm md:rounded-xl text-lg">PostgreSQL</div>
                            <div
                                className="w-32  bg-red-900/50 shadow-xl hover:shadow-red-900 hover:bg-red-900/75 backdrop-blur hover:duration-300 hover:ease-in-out cursor-default md:w-auto py-1 px-3 md:py-2 md:px-6 md:m-5 my-2 mx-2 mx-0 text-center shadow-lg rounded-sm md:rounded-xl text-lg">HTML5</div>
                            <div
                                className="w-32  bg-red-900/50 shadow-xl hover:shadow-red-900 hover:bg-red-900/75 backdrop-blur hover:duration-300 hover:ease-in-out cursor-default md:w-auto py-1 px-3 md:py-2 md:px-6 md:m-5 my-2 mx-2 mx-0 text-center shadow-lg rounded-sm md:rounded-xl text-lg">CSS3</div>
                            <div
                                className="w-32  bg-red-900/50 shadow-xl hover:shadow-red-900 hover:bg-red-900/75 backdrop-blur hover:duration-300 hover:ease-in-out cursor-default md:w-auto py-1 px-3 md:py-2 md:px-6 md:m-5 my-2 mx-2 mx-0 text-center shadow-lg rounded-sm md:rounded-xl text-lg">Docker</div>
                            <div
                                className="w-32  bg-red-900/50 shadow-xl hover:shadow-red-900 hover:bg-red-900/75 backdrop-blur hover:duration-300 hover:ease-in-out cursor-default md:w-auto py-1 px-3 md:py-2 md:px-6 md:m-5 my-2 mx-2 mx-0 text-center shadow-lg rounded-sm md:rounded-xl text-lg">TailwindCSS</div>
                            <div
                                className="w-32  bg-red-900/50 shadow-xl hover:shadow-red-900 hover:bg-red-900/75 backdrop-blur hover:duration-300 hover:ease-in-out cursor-default md:w-auto py-1 px-3 md:py-2 md:px-6 md:m-5 my-2 mx-2 mx-0 text-center shadow-lg rounded-sm md:rounded-xl text-lg">Git</div>
                            <div
                                className="w-32  bg-red-900/50 shadow-xl hover:shadow-red-900 hover:bg-red-900/75 backdrop-blur hover:duration-300 hover:ease-in-out cursor-default md:w-auto py-1 px-3 md:py-2 md:px-6 md:m-5 my-2 mx-2 mx-0 text-center shadow-lg rounded-sm md:rounded-xl text-lg">Python</div>
                        </div>
                    </div>

                </div>
            </div>
    )
}

export default AboutMe;