import React from "react";
import blog from "./static/blog_app.png"
import image_searcher from "./static/image_searcher.png"
import notes from "./static/notes.png"
import toothbrush from "./static/toothbrush.png"
import weather from "./static/weather.png"
import ecomerce from "./static/ecommerce.png"

function Projects() {
    return (
        <div className="projects" id="projects">
            <h1>PROJECTS</h1>
            <p>Here you can view the projects I've worked on while learning all the skills
                mentioned above, all the projects that you can see here are also available on my
                personal github where the source code is also viewable, if you have any
                questions or, maybe new ideas of any of the listed projects, I'd be more than
                happy to hear them so do not hesitate to contact me in that case</p>
            <div className="projects-list">
                <div className="projects-grid">
                    <img
                        src={blog}
                        alt=""
                        style={{
                        maxWidth: "500px"
                    }}/>
                    <p>This is a simple add/delete notes app that works like a "blog" page where
                        everyone can add their ideas. You can inspect/edit/delete every post added here,
                        it is purely made with node js.</p>
                    <img
                        src={image_searcher}
                        alt=""
                        style={{
                        maxWidth: "500px"
                    }}/>
                    <p>This is a simple image searcher app, you can search anything into the given
                        form. In the picture I've searched for "cats" for example, it was made with node
                        js and axios requests to the Unsplash API.</p>
                    <img
                        src={notes}
                        alt=""
                        style={{
                        maxWidth: "500px"
                    }}/>
                    <p>This is a notes app, this was my first project using React as a front end and
                        node js with postgre SQL as database, you can add notes for yourself that you
                        can also view and delete later in case those notes were done.</p>
                    <img
                        src={toothbrush}
                        alt=""
                        style={{
                        maxWidth: "500px"
                    }}/>
                    <p>SmileBrush is a simple front end website where it's advertised a product for
                        subscription.</p>
                    <img
                        src={weather}
                        alt=""
                        style={{
                        maxWidth: "500px"
                    }}/>
                    <p>This is a weather app, you search for a specific city within the globe and
                        you'll get back 7 cards with a forecast for 7 days which consists of min/max
                        temperatures as well as wind speed or chance of rain, it was made using a
                        weather api.</p>
                    <img
                        src={ecomerce}
                        alt=""
                        style={{
                        maxWidth: "500px"
                    }}/>
                    <p>This is an ecomerce app, a website where you can shop for tech or clothes items, it was made purely from imagination, did not try to copy or recreate any of the current ecomerce websites on the web, all the functionality should work although it is not entirely finished. The website can be viewed and accessed <a style={{color: "red"}} href="https://symphonious-axolotl-77221e.netlify.app">Here</a> </p>
                </div>
            </div>
        </div>
    )
}

export default Projects